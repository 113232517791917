<template>
  <div class="hh_container" :class="{'wxq_consultData': search}">
    <div class="hh_con_wrap" v-for="(item, index) in consultData" :key="index">
      <div class="hh_con " :class="{'common_inner' : !search}" @click="toConsult(item)">
        <div class="hh_img_wrap">
          <img class="hh_img" :src="item.imgUrl" alt=""/>
        </div>
        <div class="hh_info_wrap">
          <div class="hh_title_wrap">
            <p class="hh_title">{{ item.newsTitle }}</p>
            <p class="hh_watcher">
              <img src="@/assets/img/studentImg/liveDetail/watchNum-icon.png"/>
              {{ item.viewCount }}
            </p>
          </div>
          <p class="hh_info">
            {{ item.newsDesc }}
          </p>
          <p class="hh_time">{{ item.updateTime }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "consultCom",
  props: ["consultData", "search"],
  data() {
    return {};
  },
  methods: {
    toConsult(item) {
      if (!sessionStorage.getItem("educationToken")) {
        this.$store.state.isLogin = true;
      } else {
        this.$router.push({path: "/consultDetail", query: {id: item.id}});
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wxq_consultData {
  display: grid;
  grid-template-columns: 48% 48%;
  column-gap: 14px
}
.hh_container {
  padding-bottom: 16px;
  .hh_con_wrap {
    width: 100%;
    padding-top: 16px;
    .hh_con {
      cursor: pointer;
      border-radius: 8px;
      background: #ffffff;
      display: flex;
      .hh_img_wrap {
        border-radius: 8px;
        width: 20%;
        position: relative;
        overflow: hidden;
        height: 144px;
        margin: 10px 10px;
        .hh_img {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          max-width: 100%;
          margin: auto;
        }
      }
      .hh_info_wrap {
        width: 80%;
        .hh_title_wrap {
          display: flex;
          justify-content: space-between;
          padding: 8px 18px;
          .hh_title {
            width: 85%;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 20px;
            font-weight: 600;
          }
          .hh_watcher {
            width: 70px;
            height: 18px;
            background: #d4e5ff;
            border-radius: 9px;
            font-size: 12px;
            color: #3f8cff;
            text-align: center;
            line-height: 17px;
            img {
              width: 13px;
              height: 11px;
              padding-top: 4px;
            }
          }
        }
        .hh_info {
          width: 100%;
          padding: 7px 18px;
          font-size: 14px;
          height: 50px;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; //多行在这里修改数字即可
          -webkit-box-orient: vertical;
          color: #0a1629;
        }
        .hh_time {
          line-height: 60px;
          height: 60px;
          padding: 12px 18px;
          font-size: 14px;
          color: #91929e;
        }
      }
    }
  }
}
</style>
